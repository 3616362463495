/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {...} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */
/* #root {
  width: fit-content;
} */

/* 1 */
:root {
  --monica-blue: rgb(0, 0, 33.15);
  --mickey-yellow: #f9d70b;
  --mickey-red: #da0f10;
  --donald-duck-blue: #2d7eb6;
  --font-color: #333;
  --contrast-color: #eee;
  --background-color: #fff;
  --link-color: var(--donald-duck-blue);
  --warning-color: var(--mickey-yellow);
  --footer-bg-color: var(--donald-duck-blue);
  --hydrate-bg-color: green;
  --background-red: var(--mickey-red);
  --totals-bg: #d1e7dd;
  --table-options-bg-color: lightgrey;
  --input-bg-color: #eee;
  --input-color: #333;
  --btn-primary-bg: var(--donald-duck-blue);
  --totals-color: #333;
  --table-header-color: #212529;
  --table-header-bg: #fff;
}

/* 2 */
[data-theme="dark"] {
  --font-color: #eee;
  --contrast-color: #121212;
  --background-color: var(--monica-blue);
  --link-color: var(--donald-duck-blue);
  --warning-color: var(--mickey-yellow);
  --footer-bg-color: midnightblue;
  --hydrate-bg-color: green;
  --background-red: var(--mickey-red);
  --table-header-bg-color: #333;
  --table-options-bg-color: rgb(59, 58, 58);
  --input-bg-color: #333;
  --input-color: #eee;
  --btn-primary-bg: var(--donald-duck-blue);
  --totals-bg: darkslategray;
  --totals-color: #eee;
  --table-header-color: #fff;
  --table-header-bg: #212529;
  /* invert(1) hue-rotate(180deg); */
}

/* 3 */
body {
  transition: background-color 1s, color 1s ease-in-out;
  background-color: var(--background-color);
  color: var(--font-color);
}

a {
  color: var(--link-color);
}

body {
  font-family: var(--bs-body-font-family) !important;
  line-height: var(--bs-body-line-height) !important;
  overflow-x: auto !important;
}
a {
  text-decoration: underline !important;
}
th,
td {
  border-bottom-width: 0px !important;
}
.table-light > * > * {
  color: var(--table-header-color) !important;
  background-color: var(--table-header-bg) !important;
}

.table-success th {
  background-color: var(--totals-bg) !important;
  color: var(--totals-color);
}

.red {
  color: red !important;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid #ffff00;
  padding: 10px;
}

.navbar,
.container {
  display: flex;
  max-width: fit-content;
}

.navbar-brand {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.navbar-expand-lg {
  max-width: none !important;
}
.page-title {
  padding-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
  text-align: center;
}

.main {
  margin-top: 16rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
}
.toggle-prices {
  cursor: pointer;
  font-size: 32px;
  line-height: 16px;
}
.drip-price-small-screen {
  display: none;
}

.drip-pcs,
.drip-dex {
  color: white;
}

@media only screen and (min-width: 768px) {
  .stack-collapsed {
    flex-direction: row !important;
  }
  .drip-dex-collapsed {
    margin-right: 10px;
  }
  .drip-price-small-screen {
    display: none;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 700px) {
  .main {
    margin-top: 34rem;
  }
  .controls {
    display: block !important;
  }

  .navbar-brand {
    margin-right: 0px;
  }
  .toggle-prices {
    visibility: visible;
  }
  .stack-collapsed {
    flex-direction: row !important;
  }
  .drip-dex-collapsed {
    margin-right: 10px;
  }

  .drip-price-small-screen {
    display: flex;
  }
  .drip-price-large-screen {
    display: none;
  }
  .table-options {
    margin-top: 4em;
  }
}

.controls {
  display: flex;
}

.add-address {
  max-width: 35rem;
}

.totals {
  border-bottom: 10px;
}

.page-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--footer-bg-color);
  color: #eee;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  margin-right: 2rem;
  color: #eee;
}

.footer-content a {
  color: #eee;
}

.copyright {
  width: auto;
}

.affiliate {
  width: 30%;
  background-color: lightblue;
}
.affiliate img {
  width: 100%;
}

button {
  display: inline-block;
  vertical-align: top;
}

.invalid {
  color: red;
}

.hydrate {
  color: #eee;
  background-color: var(--hydrate-bg-color) !important;
}

.prepare {
  background-color: yellowgreen !important;
  color: #121212;
}

.blinking {
  animation: blinkingText 1.2s infinite;
}
@keyframes blinkingText {
  0% {
    color: #fff;
  }
  49% {
    color: #fff;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #fff;
  }
}

.price {
  display: flex;
  flex-wrap: wrap;
  height: min-content;
}
.price a {
  margin-right: 5px;
}

.prices {
  font-size: 18px;
  color: white;
  margin-right: 2rem;
  margin-left: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.table-sort-none,
.table-sort-asc,
.table-sort-desc {
  cursor: pointer;
}

.table-sort-none::after {
  content: "\2B26";
  font-size: 14px;
}

.table-sort-desc::after {
  content: "\2B19";
  margin-left: 3px;
}

.table-sort-asc::after {
  content: "\2B18";
  margin-left: 3px;
}

@media only screen and (max-width: 700px) {
  .prices {
    /* font-size: 14px; */
    margin-right: 0;
    margin-left: 0;
    justify-content: space-around;
  }
  .price-group {
    flex-direction: column;
  }

  .hidePrices {
    display: none;
  }

  .beggar {
    font-size: 10px;
  }

  .wallet-stats {
    margin: auto;
    height: 615px !important;
  }

  .bottom-section {
    flex-direction: column;
  }
}

.table-info {
  margin-top: 2rem;
}

.bi-clipboard:hover,
.bi-clipboard-check:hover {
  cursor: pointer;
}

.bi {
  margin-right: 5px;
}
.btn-copy {
  font-size: 12px;
}

.warning {
  background-color: var(--warning-color) !important;
  color: #121212 !important;
  margin-left: 5px;
}

.inputSpinner {
  margin-left: 5px;
}
.inputSpinner button {
  line-height: 1;
  width: 2rem;
  align-content: center;
  align-items: center;
}
.inputSpinner-control {
  width: 55px;
}

.input-spinner-label {
  display: flex;
}

.downline-wallet {
  cursor: pointer;
}
table {
  color: var(--font-color) !important;
}

.table-options {
  display: flex;
  justify-content: flex-start;
  background-color: var(--table-options-bg-color);
  padding: 10px;
  width: max-content;
}
.table-options-ctrl {
  margin: auto 0;
  margin-right: 1em;
  /* color: var(--font-color) !important; */
}
.table-options-ctrl > label {
  color: var(--font-color) !important;
  background-color: var(--table-options-bg-color) !important;
}
.table-options-ctrl.ui.toggle.checkbox input:checked ~ label {
  color: var(--font-color) !important;
  font-weight: bold;
}

.table-options-ctrl > input {
  color: var(--input-color) !important;
  background-color: var(--input-bg-color) !important;
}

.table-options-ctrl > select {
  color: var(--input-color) !important;
  background-color: var(--input-bg-color) !important;
}

.button {
  background-color: var(--background-color) !important;
  color: var(--font-color) !important;
}

.nav-wrap {
  flex-wrap: wrap;
}

.hide {
  display: none;
}

.hideControlsBtn {
  margin-bottom: 1rem;
}

.form-config {
  min-width: 35rem;
  margin-right: 2rem;
}

.rowIndex:hover {
  cursor: pointer;
}
.rowIndex:hover:before {
  content: "X";
  color: red;
}

.rowIndex:hover span {
  display: none;
}

.buy-more-br34p {
  background-color: var(--background-red) !important;
  color: white;
  margin-left: 5px;
}

.buy-pcs,
.buy-dex {
  color: lightgreen !important;
}
.buy-pcs::after,
.buy-dex::after {
  content: " *";
}

.drip-prices {
  display: flex;
  flex-wrap: wrap;
  width: 10rem;
}

.price-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 4rem;
}
.group-of-3 {
  width: 66%;
}

.stack {
  display: flex;
  flex-direction: column;
}

.tv-up,
.tv-up::after {
  content: "\1F680";
  margin-left: 3px;
  color: lightgreen;
}

.tv-down,
.tv-down::after {
  color: yellow;
  content: "\1FA82";
  margin-left: 3px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.btn {
  max-width: 10rem;
}
.bottom-section {
  display: flex;
}
.bottom-controls {
  width: 35rem;
}

.drip-ads {
  width: min-content;
  margin: 2em;
  background-color: white;
  flex-direction: row;
}

.file-input-wrapper {
  width: 200px;
  height: 40px;
  overflow: hidden;
  position: relative;
}
.file-input-wrapper > input[type="file"] {
  font-size: 200px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 15px solid rgba(33, 150, 243, 1);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

/* loading dots */

.dotloading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "...";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

.reload-icon {
  font-size: 1em;
  color: white;
}

.marquee-wrapper {
  display: flex;
  width: 100%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  color: var(--font-color);
  background-color: var(--background-color);
  /* width: 100%;
  padding: 2px 16px; */
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.next-reward {
  background-color: yellowgreen;
}

.wallet-stats {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 260px;
  margin-bottom: 3rem;
}
.wallet-stats > .card {
  margin: 5px;
}

.upline-table {
  font-size: 1.5rem;
  margin-bottom: 5rem;
}

.whale::after {
  content: "\1F433";
}

.config-control {
  width: min-content;
  min-height: 0;
}

.config-control > .rw-widget-input {
  width: 4rem;
}
.config-control svg {
  font-size: 12px;
}

.form-check {
  display: flex;
  /* justify-content: space-between;
  width: 28em; */
}

.form-check > div,
input {
  margin: 0 5px;
}

.marquee-ad {
  margin: 0 2em;
  padding: 0 1em;
  color: white;
  background-color: var(--background-red);
}

.marquee-ad a {
  text-decoration-color: white !important;
  color: white;
}

.tooltip-help > label {
  margin-right: 5px;
  color: #4183c4;
  cursor: pointer;
}

.btn-primary {
  background-color: var(--btn-primary-bg);
  border-color: var(--btn-primary-bg);
  color: #eee;
}

.arb {
  border: 1px solid green;
  padding: 0 5px;
}

.arb::after {
  content: " arb";
}

.note {
  font-size: x-small;
  color: lightgreen;
  margin-left: 10px;
}

.footer-links>a{
  margin: 0 2px;
}